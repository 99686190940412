import GridContainer from 'components/Grid/GridContainer';
import { HWInput } from 'HWLibrary/HWInput';
import React, { useState, Fragment } from 'react';
import GridItem from 'components/Grid/GridItem';
import ImageUpload from 'components/CustomUpload/ImageUpload';
import FileUploader from 'views/ImageUploader/FileUploader';
import { Button, makeStyles } from '@material-ui/core';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Dropzone from 'dropzone';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { People } from '@material-ui/icons';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import HWMessage from 'HWLibrary/HWMessage';
import FileUploaderSingle from './FileUploaderSingle';
import { DropzoneAreaBase, DropzoneArea } from 'material-ui-dropzone';
import { useHistory } from "react-router-dom";
import './Messaging.css'
import { DropzoneDialog } from 'material-ui-dropzone';
import campaignApiService from './APIService';
import { v4 as uuidv4 } from 'uuid';
import BlockUi from 'react-block-ui';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import cloudfrontConfig from 'HWLibrary/HWCloudfrontConfig';
import HWGoogleMaps from 'views/Google Maps/HWGoogleMaps';
import GoogleMaps from 'views/Maps/GoogleMaps';
import axios from "axios";

const useStyles = makeStyles({
    ...styles,
    textField: { textAlign: 'center' },
    fabButton: {
        backgroundColor: '#ff9017',
    },
    draftButton: { background: '#FF9017', color: 'white', marginRight: 5 },
    proceedButton: { background: '#138DE8', color: 'white' },
    cardFooter: { justifyContent: 'center' }
});

export default function Messaging(props) {
    var campaignarray = []
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    campaignarray = [...obj["Campaigns"]];

    const history = useHistory();

    const classes = useStyles();

    const [campaignDetail, setCampaignDetail] = useState(props && props.currentCampaign);
    const [createMode, setCreateMode] = useState(props && props.createMode || true);
    const [adConfig, setAdConfig] = useState(props && props.campaignDetail && props.campaignDetail.adConfig || {});
    const [hasError, setHasError] = useState({});
    const [errorMessage, setErrorMessage] = useState({});
    const [valid, setValid] = useState(false);
    const [openSingle, setOpenSingle] = React.useState(false);
    const [openMultiple, setOpenMultiple] = React.useState(false);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [didFileUploadFail, setDidFileUploadFail] = useState(false);
    const [keyFileName, setKeyFileName] = useState("");
    const { currentCampaign, setCurrentCampaign } = props;
    // const [ location, setLocation ] = useState ({ latitude : 3.1569, longitude : 101.7123, detailedAddress : '' });
    const [location, setLocation] = useState(props && props.currentCampaign && props.currentCampaign.eventSchedule && props.currentCampaign.eventSchedule.locationCoordinates ? props.currentCampaign.eventSchedule.locationCoordinates : { latitude: 5, longitude: 70, detailedAddress: '' });
    const [address, setAddress] = useState(props && props.currentCampaign && props.currentCampaign.eventSchedule && props.currentCampaign.eventSchedule.address || '');
    const [locationNotFound, setLocationNotFound] = useState(false)
    console.log(currentCampaign, createMode);


    const uploadBulkFile = (file, fileName) => {

        setDidFileUploadFail(false);
        campaignApiService.fetchS3PresignedUrl(fileName).then(response => {
            setDidFileUploadFail(false);
            console.log(response.data.presignedUrl);

            setKeyFileName(response.data.keyName);
            multibulkPartUpload(file, response.data.presignedUrl);

        }).catch((reason) => {
            setDidFileUploadFail(true);
            setUploadInProgress(true);
            setUploadInProgress(false);
        })
    }

    const multibulkPartUpload = (file, preSignedURL) => {
        campaignApiService.uploadFile(file, preSignedURL).then(response => {

            setDidFileUploadFail(false);
            setUploadInProgress(false);

        }).catch((reason) => {
            setDidFileUploadFail(false);
            setUploadInProgress(false);

        })
    }

    const logBreaker = (currentCampaign) => {
        console.log("jghdisahdjsaiohdasohdhasohdisajdsa", currentCampaign);
        return undefined;
    };


    const validateConfiguration = (currentCampaign) => {
        console.log(".....validtaion ", currentCampaign);
        switch (currentCampaign.type) {
            case 'Advertisement':
                if (props.campaignMode === false) {
                    if (props.currentCampaign && props.currentCampaign.eventSchedule && props.currentCampaign.title && props.currentCampaign.title !== null && props.currentCampaign.title !== '' && props.currentCampaign.content && props.currentCampaign.content !== null && currentCampaign.content !== '' && (props.currentCampaign && props.currentCampaign.bannerImageUrl && props.currentCampaign.bannerImageUrl !== '' && props.currentCampaign.bannerImageUrl !== null)
                        && (props.currentCampaign && props.currentCampaign.contentImageUrls && props.currentCampaign.contentImageUrls.length && props.currentCampaign.contentImageUrls.length >= 1 && props.currentCampaign.contentImageUrls.length <= 6)) {
                        if (props.currentCampaign.eventSchedule.linkName && props.currentCampaign.eventSchedule.linkName !== '') {
                            if (props.currentCampaign.eventSchedule.linkUrl && props.currentCampaign.eventSchedule.linkUrl != '')
                                return true;
                            else {
                                return false;
                            }
                        }

                        if (props.currentCampaign.eventSchedule.linkUrl && props.currentCampaign.eventSchedule.linkUrl != '') {
                            if (props.currentCampaign.eventSchedule.linkName && props.currentCampaign.eventSchedule.linkName !== '')
                                return true;
                            else {
                                return false;
                            }
                        }
                        return true;
                    }
                }
                else {
                    if ((currentCampaign.title && currentCampaign.title !== null && currentCampaign.title !== '') &&
                        (currentCampaign.content && currentCampaign.content !== null && currentCampaign.content !== '') &&
                        (currentCampaign && currentCampaign.bannerImageUrl && currentCampaign.bannerImageUrl !== '' && currentCampaign.bannerImageUrl !== null)
                        && (currentCampaign && currentCampaign.contentImageUrls && currentCampaign.contentImageUrls.length && currentCampaign.contentImageUrls.length >= 1 && currentCampaign.contentImageUrls.length <= 6)) {
                        if (currentCampaign.adConfig.linkName && currentCampaign.adConfig.linkName !== null && currentCampaign.adConfig.linkName !== '') {
                            if (currentCampaign.adConfig.linkUrl && currentCampaign.adConfig.linkUrl !== null && currentCampaign.adConfig.linkUrl !== '')
                                return true;
                            else {
                                return false;
                            }
                        }
                        if (currentCampaign.adConfig.linkUrl && currentCampaign.adConfig.linkUrl !== null && currentCampaign.adConfig.linkUrl !== '') {
                            if (currentCampaign.adConfig.linkName && currentCampaign.adConfig.linkName !== null && currentCampaign.adConfig.linkName !== '')
                                return true;
                            else {
                                return false;
                            }
                        }
                        return true;
                    }


                }
                break;
            case 'GenBluStore':
                if ((props.campaignMode === false && props.currentCampaign.eventSchedule && props.currentCampaign.eventSchedule.linkName && props.currentCampaign.eventSchedule.linkName !== '' && props.currentCampaign.eventSchedule.linkUrl && props.currentCampaign.eventSchedule.linkUrl !== '' && props.currentCampaign.bannerImageUrl && props.currentCampaign.bannerImageUrl !== '' && props.currentCampaign.bannerImageUrl !== null && props.currentCampaign.contentImageUrls && props.currentCampaign.contentImageUrls.length >= 1 && props.currentCampaign.contentImageUrls.length <= 6)
                    || (currentCampaign.title && currentCampaign.title !== null && currentCampaign.title !== '') &&
                    (currentCampaign.content && currentCampaign.content !== null && currentCampaign.content !== '') &&
                    (currentCampaign.genbluEcommerceConfig && currentCampaign.genbluEcommerceConfig.linkName && currentCampaign.genbluEcommerceConfig.linkName !== null && currentCampaign.genbluEcommerceConfig.linkName !== '' && currentCampaign.genbluEcommerceConfig.linkUrl && currentCampaign.genbluEcommerceConfig.linkUrl !== null && currentCampaign.genbluEcommerceConfig.linkUrl !== '')
                    && (currentCampaign.bannerImageUrl && currentCampaign.bannerImageUrl !== '' && currentCampaign.bannerImageUrl !== null)
                    && (currentCampaign.contentImageUrls && currentCampaign.contentImageUrls.length >= 1 && currentCampaign.contentImageUrls.length <= 6)) {
                    console.log("dsaasd");
                    return true;
                }
                break;
            case 'Event':

                if ((props.campaignMode === false && props.currentCampaign.eventSchedule && props.currentCampaign.eventSchedule.address && props.currentCampaign.eventSchedule.addressCoordinates) || (currentCampaign.title && currentCampaign.title !== null && currentCampaign.title !== '') && (currentCampaign.content && currentCampaign.content !== null && currentCampaign.content !== '') && (currentCampaign.campaignEventConfig && currentCampaign.campaignEventConfig.address && currentCampaign.campaignEventConfig.address !== null && currentCampaign.campaignEventConfig.address !== '') && (currentCampaign.bannerImageUrl && currentCampaign.bannerImageUrl !== '' && currentCampaign.bannerImageUrl !== null) && (currentCampaign.contentImageUrls && currentCampaign.contentImageUrls.length && currentCampaign.contentImageUrls.length >= 1 && currentCampaign.contentImageUrls.length <= 6)) {
                    return true;
                }
                break;
            case 'MerchantEvent':
                if ((props.campaignMode === false && props.currentCampaign.eventSchedule && props.currentCampaign.eventSchedule.address && props.currentCampaign.eventSchedule.addressCoordinates)
                    || (currentCampaign.title && currentCampaign.title !== null && currentCampaign.title !== '') && (currentCampaign.content && currentCampaign.content !== null && currentCampaign.content !== '')
                    && (currentCampaign.campaignMerchantEventConfig && currentCampaign.campaignMerchantEventConfig.address && currentCampaign.campaignMerchantEventConfig.address !== null && currentCampaign.campaignMerchantEventConfig.address !== '') &&
                    (currentCampaign.bannerImageUrl && currentCampaign.bannerImageUrl !== '' && currentCampaign.bannerImageUrl !== null) && (currentCampaign.contentImageUrls && currentCampaign.contentImageUrls.length && currentCampaign.contentImageUrls.length >= 1
                        && currentCampaign.contentImageUrls.length <= 6)) {
                    return true;
                }

                break;
        }
        return false;
    }

    const getCoordinates = async () => {
        const res = await axios.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + address + "&key=AIzaSyADUBIkqzAu4Z0v65kVuCGy-UbxNSZIHFE");
        console.log(res.data.results[0]);
        const merchantId = props?.currentCampaign?.eventSchedule?.merchantId;
        if (res.data.results[0]) {
            setLocationNotFound(false)
            setLocation(prev => ({ latitude: res.data.results[0].geometry.location.lat, longitude: res.data.results[0].geometry.location.lng, detailedAddress: res.data.results[0].formatted_address }));
            assignValueByCampaignType('merchantId', merchantId, 'addressCoordinates', { latitude: res.data.results[0].geometry.location.lat, longitude: res.data.results[0].geometry.location.lng, detailedAddress: res.data.results[0].formatted_address });
            setCurrentCampaign(prev => ({ ...prev, eventSchedule: { ...prev.eventSchedule, ['addressCoordinates']: { latitude: res.data.results[0].geometry.location.lat, longitude: res.data.results[0].geometry.location.lng, detailedAddress: res.data.results[0].formatted_address } } }))
        }
        //console.log({ latitude : res.data.results[0].geometry.location.lat, longitude : res.data.results[0].geometry.location.lng, detailedAddress : res.data.results[0].formatted_address })
        else {
            setLocationNotFound(true)
            setCurrentCampaign(prev => ({ ...prev, eventSchedule: { ...prev.eventSchedule, ["address"]: '' } }))
            assignValueByCampaignType('merchantId', merchantId, 'addressCoordinates', props && props.currentCampaign && props.currentCampaign.eventSchedule && props.currentCampaign.eventSchedule.locationCoordinates ? props.currentCampaign.eventSchedule.locationCoordinates : { latitude: 3.1569, longitude: 101.7123, detailedAddress: '' });
            setCurrentCampaign(prev => ({ ...prev, eventSchedule: { ...prev.eventSchedule, ...["addressCoordinates"], detailedAddress: '' } }))

        }
    };

    const getDetailedAddress = async (lat, lng) => {
        const res = await axios.get("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + ',' + lng + "&key=AIzaSyADUBIkqzAu4Z0v65kVuCGy-UbxNSZIHFE");
        console.log(res.data.results[0]);
        const merchantId = props?.currentCampaign?.eventSchedule?.merchantId;
        setLocation(prev => ({ latitude: res.data.results[0].geometry.location.lat, longitude: res.data.results[0].geometry.location.lng, detailedAddress: res.data.results[0].formatted_address }));
        assignValueByCampaignType('merchantId', merchantId, 'addressCoordinates', { latitude: res.data.results[0].geometry.location.lat, longitude: res.data.results[0].geometry.location.lng, detailedAddress: res.data.results[0].formatted_address });
        setCurrentCampaign(prev => ({ ...prev, eventSchedule: { ...prev.eventSchedule, ['addressCoordinates']: { latitude: res.data.results[0].geometry.location.lat, longitude: res.data.results[0].geometry.location.lng, detailedAddress: res.data.results[0].formatted_address } } }))

        console.log({ latitude: res.data.results[0].geometry.location.lat, longitude: res.data.results[0].geometry.location.lng, detailedAddress: res.data.results[0].formatted_address })

    }

    const saveDraft = event => {
        if (!currentCampaign || currentCampaign === null) { return HWMessage.show("Please enter one or more detail to save as campaign", "error"); }
        props.saveDraft();
        history.push('/ops/campaigns');
    };

    const handleDraftClick = (event) => {
        props.setOpen(true);

        //saveDraft (event);
    }

    const handleBackClick = (event) => {
        props.backStep();
    }
    const checkDisabled = () => {

        //  alert (JSON.stringify(props.currentCampaign));
        if (props.campaignMode === false) {
            if (props.currentCampaign && props.currentCampaign.status && props.currentCampaign.status === 'Draft') {
                return false;
            } else if (props.currentCampaign && props.currentCampaign.status && props.currentCampaign.status === 'Enabled') {
                if (props.campaignType === 'Live')
                    return true;
            }
        } else {
            return false;
        }
    }

    const saveProceed = event => {
        //  let check = validateAllValues ();
        let validation = validateConfiguration(currentCampaign);
        if (validation === false)
            return HWMessage.show("Errors in one or more fields, please rectify before proceeding. Click 'Find on Map' or move the pin to confirm location", "error");
        else {
            props.advanceStep();
        }

    };
    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    };

    const validateCampaignValues = (field, value) => {
        switch (field) {
            case 'title':
                if (!value || value === '') {
                    setHasError({ ...hasError, 'title': true });
                    setErrorMessage({ ...errorMessage, 'title': 'Title must be specified' });
                    return false;
                } else if (value.length > 100) {
                    setHasError({ ...hasError, 'title': true });
                    setErrorMessage({ ...errorMessage, 'title': 'Title cannot be greater than 100 words' });
                    return false;
                } else {
                    setHasError({ ...hasError, 'title': false });
                    setErrorMessage({ ...errorMessage, 'title': null });
                    setValid(true);

                    return true;
                }
            case 'content':
                if (!value || value == null || value == '') {
                    setHasError({ ...hasError, 'content': true });
                    setErrorMessage({ ...errorMessage, 'content': 'Content must be specified' });
                    return false;
                } else {
                    setHasError({ ...hasError, 'content': false });
                    setErrorMessage({ ...errorMessage, 'content': null });
                    setValid(true);

                    return true;
                }
            case 'linkName':
                if (!value || value == null || value === '') {
                    setHasError({ ...hasError, 'linkName': true });
                    setErrorMessage({ ...errorMessage, 'linkName': 'Link should be specified' });
                    return false;
                } else {
                    setHasError({ ...hasError, 'linkName': false });
                    setErrorMessage({ ...errorMessage, 'linkName': null });
                    setValid(true);

                    return true;
                }
            case 'linkUrl':
                if (!value || value == null || value === '') {
                    setHasError({ ...hasError, 'linkUrl': true });
                    setErrorMessage({ ...errorMessage, 'linkUrl': 'Link should be specified' });

                    return false;
                } else {
                    setHasError({ ...hasError, 'linkUrl': false });
                    setErrorMessage({ ...errorMessage, 'linkUrl': null });
                    setValid(true);

                    return true;

                }
            case 'address':
                if (!value || value == null || value === '') {
                    setHasError({ ...hasError, 'address': true });
                    setErrorMessage({ ...errorMessage, 'address': 'Address should be specified' });
                    return false;
                } else {
                    setHasError({ ...hasError, 'address': false });
                    setErrorMessage({ ...errorMessage, 'address': null });
                    setValid(true);

                    return true;
                }
        }
    };




    const fetchFieldByCampaignType = fieldName => {
        if (fieldName === 'linkName' || fieldName === 'linkUrl') {
            if (currentCampaign.type === 'GenBluStore') { return currentCampaign.genbluEcommerceConfig[fieldName]; }
            else if (currentCampaign.type === 'Advertisement') { return currentCampaign.adConfig[fieldName]; }
        } else if (fieldName === 'address') {
            if (currentCampaign.type === 'Event') { return currentCampaign.campaignEventConfig[fieldName]; }
            else if (currentCampaign.type === 'MerchantEvent') { return currentCampaign.campaignMerchantEventConfig[fieldName]; }
        }
    };
    const assignValueByCampaignType = (field, value) => {
        if (field === 'linkName' || field === 'linkUrl') {
            if (currentCampaign.type === 'GenBluStore') { setCurrentCampaign(prev => ({ ...prev, genbluEcommerceConfig: { ...prev.genbluEcommerceConfig, [field]: value } })) }
            else if (currentCampaign.type === 'Advertisement') { setCurrentCampaign(prev => ({ ...prev, adConfig: { ...prev.adConfig, [field]: value } })) }
        }
        else if (field === 'address' || field === 'addressCoordinates') {
            if (currentCampaign.type === 'Event') { setCurrentCampaign(prev => ({ ...prev, campaignEventConfig: { ...prev.campaignEventConfig, [field]: value } })); }
            else if (currentCampaign.type === 'MerchantEvent') { setCurrentCampaign(prev => ({ ...prev, campaignMerchantEventConfig: { ...prev.campaignMerchantEventConfig, [field]: value } })) }
        }


    };

    const captureInput = event => {
        const field = event.target.name;
        const value = event.target.value;

        var ans = validateCampaignValues(field, value);
        console.log("Current", currentCampaign, hasError, errorMessage);

        switch (field) {
            case 'title': props.setCurrentCampaign(prev => ({ ...prev, [field]: value })); break;
            case 'content': props.setCurrentCampaign(prev => ({ ...prev, [field]: value })); break;
            case 'linkName': if (props.campaignMode === false) {
                setCurrentCampaign(prev => ({ ...prev, eventSchedule: { ...prev.eventSchedule, [field]: value } }))
            }
            else {
                assignValueByCampaignType(field, value);
            } break;
            case 'linkUrl':
                if (props.campaignMode === false) {
                    setCurrentCampaign(prev => ({ ...prev, eventSchedule: { ...prev.eventSchedule, [field]: value } }))
                }
                else {
                    assignValueByCampaignType(field, value);
                }
                break;
            case 'address':
                setAddress(event.target.value)
                if (props.campaignMode === false) {
                    setCurrentCampaign(prev => ({ ...prev, eventSchedule: { ...prev.eventSchedule, [field]: value } }))
                    setCurrentCampaign(prev => ({ ...prev, eventSchedule: { ...prev.eventSchedule, ['addressCoordinates']: location } }))
                    // setCurrentCampaign (prev => ({ ...prev, eventSchedule : { ...prev.eventSchedule, [ 'detailedAddress' ] : detailedAddress } })) 
                }
                else {
                    assignValueByCampaignType(field, value);
                    assignValueByCampaignType('addressCoordinates', location);
                    // assignValueByCampaignType ('detailedAddress', value);  
                }

        }
        console.log("event", event, event.target, event.target.name, event.target.value);
        console.log("Current", currentCampaign, hasError, errorMessage);
    };
    let b = [];
    return (
        <Fragment>
            <Card>
                <CardHeader className={classes.cardHeader} color='rose' icon >
                    <CardIcon style={{ background: '#138DE8' }} color='primary'> <People /> </CardIcon>
                    <h4 className={classes.cardIconTitle} > Messaging  </h4>
                </CardHeader>
                <CardBody>
                    <BlockUi blocking={uploadInProgress} message={'Uploading Image'} >

                        <GridContainer>

                            <GridItem xs={12} md={6} sm={12} >
                                <HWInput fullWidth label="Campaign Title" name='title'
                                    value={currentCampaign.title} onBlur={captureInput}
                                    required={true} hasError={checkError('title')} helperText={fetchErrorMessage('title')}
                                />
                            </GridItem>
                            <GridItem xs={12} md={6} sm={12} >
                                <HWInput fullWidth multiline label="Campaign Content" name='content'
                                    value={currentCampaign.content} onBlur={captureInput}
                                    required={true} hasError={checkError('content')} helperText={fetchErrorMessage('content')}
                                />
                            </GridItem>
                            <GridItem xs={12} md={12} sm={12} >
                                <center>
                                    <h4> Banner Image Upload </h4>
                                    <div>
                                        <Button variant="contained" color="primary" onClick={() => setOpenSingle(true)}>
                                            {props.campaignMode === true ? "Add Image " : "View Image"}
                                        </Button>
                                        <DropzoneDialog
                                            clearOnUnmount={false}
                                            initialFiles={[currentCampaign.bannerImageUrl]}
                                            acceptedFiles={['image/*']}
                                            cancelButtonText={"cancel"}
                                            submitButtonText={"submit"}
                                            maxFileSize={5000000}
                                            dropzoneText={"Please upload image with aspect-ratio 1.78:1 and dimensions 1920 x 1080."}
                                            dropzoneParagraphClass={"abc"}
                                            open={openSingle}
                                            filesLimit={1}
                                            onClose={() => setOpenSingle(false)}
                                            onDelete={(deletedFile) => setCurrentCampaign(prev => ({ ...prev, bannerImageUrl: '' }))}
                                            onSave={(bannerImageFile) => {
                                                const img = new Image();
                                                img.src = URL.createObjectURL(bannerImageFile[0]);
                                                img.onload = function () {
                                                    const height = this.height;
                                                    const width = this.width;
                                                    console.log('Image dimensions:', width, 'x', height);
                                                    if (width != 1920 || height != 1080) {
                                                        HWMessage.show("Please upload the image of proper Dimensions")
                                                        setOpenSingle(true);
                                                        return;
                                                    }
                                                };
                                                setUploadInProgress(true);
                                                let myuuid = uuidv4();
                                                let file = bannerImageFile[0];
                                                let fileName = file.name;
                                                if (file != null) {
                                                    let fileName = file.name
                                                    uploadBulkFile(file, myuuid + fileName);
                                                }
                                                setCurrentCampaign(prev => ({ ...prev, bannerImageUrl: `${cloudfrontConfig.cloudfrontBaseUrl}/campaign-images/${myuuid + fileName}` }));
                                                setOpenSingle(false);
                                            }}
                                            showPreviews={true}
                                            showFileNamesInPreview={true}
                                            fullWidth={true}

                                        />
                                    </div>
                                </center>
                            </GridItem>
                            <br />
                            <br />
                            <GridItem xs={12} md={12} sm={12} >
                                <center>
                                    <h4> Campaign Images Upload  </h4>
                                    <div>
                                        <Button variant="contained" color="primary" onClick={() => setOpenMultiple(true)}>
                                            {props.campaignMode === true ? "Add Images" : "View Images"}
                                        </Button>

                                        <DropzoneDialog
                                            initialFiles={currentCampaign.contentImageUrls}
                                            clearOnUnmount={false}
                                            dropzoneText={"Please upload images with aspect-ratio 1.78:1 and dimensions 1920 x 1080."}
                                            dropzoneParagraphClass={"abc"}
                                            acceptedFiles={['image/*']}
                                            cancelButtonText={"cancel"}
                                            submitButtonText={"submit"}
                                            maxFileSize={5000000}
                                            open={openMultiple}
                                            fullWidth={true}
                                            filesLimit={6}
                                            onClose={() => setOpenMultiple(false)}
                                            onDelete={(deletedFile) => setCurrentCampaign(prev => ({ ...prev, contentImageUrls: prev.contentImageUrls && prev.contentImageUrls.filter(name => (name !== `${cloudfrontConfig.cloudfrontBaseUrl}/campaign-images/${deletedFile.name}`)) }))}
                                            onSave={(contentImageFiles) => {
                                                let a = [];
                                                for (var i = 0; i < contentImageFiles.length; i++) {
                                                    console.log(contentImageFiles[i]);
                                                    const img = new Image();
                                                    img.src = URL.createObjectURL(contentImageFiles[i]);
                                                    img.onload = function () {
                                                        const height = this.height;
                                                        const width = this.width;
                                                        console.log('Image dimensions:', width, 'x', height);
                                                        if (width != 1920 || height != 1080) {
                                                            HWMessage.show("Please upload the image of proper Dimensions")
                                                            setOpenMultiple(true);
                                                            return;
                                                        }
                                                    };
                                                    setUploadInProgress(true);
                                                    let myuuid = uuidv4();
                                                    let file = contentImageFiles[i];
                                                    if (file != null) {
                                                        let fileName = file.name
                                                        uploadBulkFile(file, myuuid + fileName);
                                                        a.push(`${cloudfrontConfig.cloudfrontBaseUrl}/campaign-images/${myuuid + fileName}`);
                                                    }
                                                    setCurrentCampaign(prev => ({ ...prev, contentImageUrls: a }));
                                                }
                                                setOpenMultiple(false);
                                            }}
                                            showPreviews={true}
                                            showFileNamesInPreview={true}
                                        />
                                    </div>
                                </center>
                            </GridItem>
                            {
                                currentCampaign.type && (currentCampaign.type === 'GenBluStore' || currentCampaign.type === 'Advertisement') &&
                                <>
                                    <GridItem xs={12} md={6} sm={12} >
                                        <HWInput fullWidth label="Link" name='linkUrl'
                                            value={props.campaignMode === true ? fetchFieldByCampaignType('linkUrl') : (currentCampaign.eventSchedule && currentCampaign.eventSchedule.linkUrl) ? currentCampaign.eventSchedule.linkUrl : ""}
                                            onBlur={captureInput}
                                            required={currentCampaign.type === "Advertisement" ? true : false} hasError={checkError('linkUrl')} helperText={fetchErrorMessage('linkUrl')}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} md={6} sm={12} >
                                        <HWInput fullWidth label="Link Name" name='linkName'
                                            value={props.campaignMode === true ? fetchFieldByCampaignType('linkName') : (currentCampaign.eventSchedule && currentCampaign.eventSchedule.linkName) ? currentCampaign.eventSchedule.linkName : ""}
                                            onBlur={captureInput}
                                            required={currentCampaign.type === "Advertisement" ? true : false} hasError={checkError('linkName')} helperText={fetchErrorMessage('linkName')}
                                        />
                                    </GridItem>
                                </>
                            }
                            {
                                currentCampaign.type && (currentCampaign.type === 'Event' || currentCampaign.type === 'MerchantEvent') &&
                                <GridItem xs={12} md={6} sm={12} >
                                    <HWInput fullWidth label="Event Location" name='address'
                                        value={props.campaignMode === true ? fetchFieldByCampaignType('address') : currentCampaign.eventSchedule && currentCampaign.eventSchedule.address ? currentCampaign.eventSchedule.address : ""}
                                        onBlur={captureInput}
                                        required={true} hasError={checkError('address')} helperText={fetchErrorMessage('address')}
                                    />
                                    {currentCampaign && currentCampaign.eventSchedule && currentCampaign.eventSchedule.addressCoordinates && currentCampaign.eventSchedule.addressCoordinates.detailedAddress && <p>{currentCampaign.eventSchedule.addressCoordinates.detailedAddress}</p>}
                                    <p><text>{locationNotFound ? "Sorry, could not find the above location. Try entering a nearby location name" : "Move the pin on the map to set exact location"}</text></p>
                                    <p><Button style={{ backgroundColor: '#FF9017', color: 'floralwhite', width: '200px' }} raised onClick={e => getCoordinates()} > Find on Map </Button></p>

                                    {/* <HWGoogleMaps/> */}
                                    {/* <HWInput style={{backgroundColor: 'white' }} name = { 'address' }  onBlur ={ captureInput } label = { 'Address' } labelProps = {{ shrink: true }} /> 
                                */}
                                    {/* <HWGoogleMaps lat = { location.latitude } lng= {location.longitude} setLocation={setLocation} />  */}


                                    {/* {/* { JSON.stringify(location) /*} */}
                                    <HWGoogleMaps lat={location.latitude} lng={location.longitude} getDetailedAddress={getDetailedAddress} />
                                </GridItem>
                            }

                        </GridContainer>
                    </BlockUi>

                </CardBody>

                <CardFooter className={classes.cardFooter} >
                    <center>
                        <Button variant="contained" style={{ backgroundColor: '#FF9017', color: 'floralwhite', textTransform: 'none' }} onClick={e => handleBackClick(e)} > Back </Button>
                        {props.campaignType === 'Expired' && <Button variant="contained" style={{ backgroundColor: '#138de8', color: 'floralwhite', textTransform: 'none' }} onClick={e => history.push('/ops/campaigns')} > Close </Button>}

                        {campaignarray.includes("Action") === true && props.campaignType !== 'Expired' && <Button variant="contained" style={{ backgroundColor: '#138DE8', color: 'floralwhite', textTransform: 'none' }} disabled={checkDisabled()} onClick={handleDraftClick} > Save as Draft </Button>
                        }                            <Button variant="contained" style={{ backgroundColor: '#FF9017', color: 'floralwhite', textTransform: 'none' }} disabled={props.campaignMode === false ? false : props.currentStep < props.prevStep && !valid ? false : !valid} onClick={saveProceed} > Proceed </Button>
                    </center>
                </CardFooter>
            </Card>
        </Fragment>
    );
}