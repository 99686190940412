import { makeStyles } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Stepper, Step, Typography, StepContent, StepLabel } from '@material-ui/core';
import CampaignSelection from './CampaignSelection';
import Messaging from './Messaging';
import PointsRule from './PointsRule';
import Notification from './Notification';
import Scheduling from './Scheduling';
import HWMessage from 'HWLibrary/HWMessage';
import { useCallback } from 'react';
import campaignApiService from './APIService';
import { transform } from 'typescript';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import { useHistory } from "react-router-dom";
import HWLocalStorage from 'HWLibrary/HWLocalStorage';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),

    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export default function CreateCampaign(props) {
    const history = useHistory();


    // Extracting the state params from the location props.
    console.log("Props", props);
    const extractLocationParams = () => {
        console.log("Inside", props);
        const locationParams = props && props.location && props.location.params;
        console.log("Locatrion params", locationParams);
        if (locationParams != null) { return locationParams.state; }
        else { return {}; }
    };

    const locationParams = extractLocationParams();
    console.log("Extracted", locationParams);

    const [createMode, setCreateMode] = useState(locationParams.createMode ? locationParams.createMode : true);
    const [campaignDetail, setCampaignDetail] = useState({});
    const [draftConfirmation, setDraftConfirmation] = useState(false);
    const [prevStep, setPrevStep] = useState(-1);
    const [saveConfirmation, setSaveConfirmation] = useState(false);

    const [loading, setLoading] = useState(false);
    const [campaignType, setCampaignType] = useState('');
    const [currentStep, setCurrentStep] = useState(0);
    const [steps, setSteps] = useState(['Campaign and User Selection', 'Messaging', 'Points Rule', 'Notify Via', 'Scheduling']);



    useEffect(() => {

        if (locationParams.createMode !== undefined) {
            HWLocalStorage.save(locationParams.createMode, 'createMode');

            HWLocalStorage.save(locationParams.campaignId, 'CampaignId');
        }

    }, [])


    useEffect(() => {
        console.log("Here", locationParams);
        if (HWLocalStorage.load("createMode") === false) {
            if (HWLocalStorage.load("CampaignId") != null) {
                setLoading(true);
                console.log("Very inside here", locationParams);
                campaignApiService.fetchCampaign(HWLocalStorage.load("CampaignId"))

                    .then(response => {
                        if (!response || !response.data) { return HWMessage.show("Campaign cannot be fetched at moment"); }
                        setCampaignDetail(response.data);
                        setCampaignDetail(prev => ({
                            ...prev, eventConfig: prev.eventSchedule, campaignNotificationConfig: {
                                ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig,
                                emailNotificationStatus: prev.campaignNotificationConfig && prev.campaignNotificationConfig.emailNotificationStatus === "Enabled" ? true : false,
                                smsNotificationStatus: prev.campaignNotificationConfig && prev.campaignNotificationConfig.emailNotificationStatus === "Enabled" ? true : false,
                                pushNotificationStatus: prev.campaignNotificationConfig && prev.campaignNotificationConfig.emailNotificationStatus === "Enabled" ? true : false
                            }
                        })
                        );
                        HWMessage.show("Request Successful", "success");

                    })
                    .catch(error => { return HWMessage.show("Error while fetching campaigns"); })
                    .finally(() => setLoading(false));
            }
        }
    }, []);


    const advanceStep = () => {
        if (campaignDetail && campaignDetail.type === 'Advertisement') {
            if (currentStep === 1) { setCurrentStep(prev => prev + 2); }
            else { setCurrentStep(prev => prev + 1); }
        }
        else { setCurrentStep(prev => prev + 1); }
    };

    const backStep = () => {
        setPrevStep(currentStep);
        if (campaignDetail && campaignDetail.type === 'Advertisement') {
            if (currentStep === 3) { setCurrentStep(prev => prev - 2); }
            else { setCurrentStep(prev => prev - 1); }
        }
        else { setCurrentStep(prev => prev - 1); }
    };

    const updateCampaign = useCallback(campaignDetail => {
        console.log('update campaign called');

        if (campaignDetail && campaignDetail !== null && campaignDetail.status !== null && campaignDetail.status === 'Draft')
            campaignDetail.status = 'Enabled';
        console.log('campaigned call status set to enabled');


        if (campaignDetail) {
            console.log("Campaign detail to update", campaignDetail);
            var eventConfig = transformCampaign(campaignDetail);
            console.log(eventConfig);
            campaignDetail.eventConfig = eventConfig;
            if (Object.keys(eventConfig).length === 0) {
                campaignDetail.eventConfig = campaignDetail.eventSchedule;
            }
            campaignApiService.updateCampaign(campaignDetail)
                .then(response => {
                    if (!response || !response.data) { return HWMessage.show("Error while fetching the response"); }
                    return HWMessage.show("Campaign Updated Successfully");
                })
                .catch(error => { HWMessage.show("Error while updating campaign ") })
                .finally(() => {
                    history.push('/ops/campaigns');
                });
        }
    }, []);

    const transformCampaign = campaignDetail => {
        var eventConfig = {};
        if (campaignDetail && campaignDetail !== null) {
            switch (campaignDetail.type) {
                case 'Advertisement': if (campaignDetail.adConfig) {
                    for (let [key, value] of Object.entries(campaignDetail.adConfig)) { eventConfig[key] = value };
                } break;
                case 'GenBluStore': if (campaignDetail.genbluEcommerceConfig) {
                    for (let [key, value] of Object.entries(campaignDetail.genbluEcommerceConfig)) { eventConfig[key] = value };
                } break;
                case 'Event':
                    if (campaignDetail.campaignEventConfig) {
                        for (let [key, value] of Object.entries(campaignDetail.campaignEventConfig)) { eventConfig[key] = value; }
                        if (eventConfig.qrCodeScanPermission === null)
                            eventConfig.qrCodeScanPermission = "MerchantAndMember";
                    }
                    break;
                case 'MerchantEvent':
                    if (campaignDetail.campaignMerchantEventConfig) {
                        for (let [key, value] of Object.entries(campaignDetail.campaignMerchantEventConfig)) { eventConfig[key] = value }
                        if (eventConfig.qrCodeScanPermission === null)
                            eventConfig.qrCodeScanPermission = "MerchantAndMember";
                    }
                    break;
            }
            if (eventConfig.eventStartTime) { var value = eventConfig.eventStartTime; value = value.slice(0, -1); eventConfig.eventStartTime = value + 'Z'; }
            if (eventConfig.eventEndTime) { var value = eventConfig.eventEndTime; value = value.slice(0, -1); eventConfig.eventEndTime = value + 'Z'; }

        }
        return eventConfig;
    }


    const createCampaign = useCallback(campaignDetail => {

        console.log(campaignDetail);

        if (campaignDetail) {
            var eventConfig = transformCampaign(campaignDetail);
            console.log(eventConfig);
            campaignDetail.eventConfig = eventConfig;
            campaignApiService.createCampaign(campaignDetail)
                .then(response => {
                    if (!response || !response.data) { return HWMessage.show("Error while creating campaign", "error"); }
                    history.push('/ops/campaigns');
                    setSaveConfirmation(false);
                    return HWMessage.show("Campaign created successfully", "success");

                })
                .catch(error => {
                    history.push('/ops/campaigns');
                    return HWMessage.show("Error while creating campaign", "error")
                });
        }
    }, [])

    const validateCampaignValues = () => {

        // TODO : Validate all the values within.
        return true;
    };

    const saveAsDraft = () => {
        setDraftConfirmation(true);
        let campaign = {
            ...campaignDetail,
            campaignNotificationConfig: {
                ...(campaignDetail.campaignNotificationConfig) && campaignDetail.campaignNotificationConfig,
                emailNotificationStatus: campaignDetail.campaignNotificationConfig && campaignDetail.campaignNotificationConfig.emailNotificationStatus === true ? "Enabled" : "Disabled",
                smsNotificationStatus: campaignDetail.campaignNotificationConfig && campaignDetail.campaignNotificationConfig.smslNotificationStatus === true ? "Enabled" : "Disabled",
                pushNotificationStatus: campaignDetail.campaignNotificationConfig && campaignDetail.campaignNotificationConfig.pushNotificationStatus === true ? "Enabled" : "Disabled",
            }
        }

        console.log("Drafted Request Object", campaign);
        if (campaignDetail === null)
            return HWMessage.show("Error while creating draft", "error");
        campaign.status = "Draft";

        setLoading(true);
        //     debugger;
        var eventConfig = transformCampaign(campaign);
        console.log(eventConfig);
        campaign.eventConfig = eventConfig;
        if (Object.keys(eventConfig).length === 0) {
            campaign.eventConfig = campaign.eventSchedule;
        }
        if (campaign && campaign.id && campaign.id !== null) {
            campaignApiService.updateCampaign(campaign)
                .then(response => {
                    if (!response || !response.data) { return HWMessage.show("Error while updating campaign", "error"); }
                    setCampaignDetail(prev => { prev.status = 'Draft'; return { ...prev }; });
                    HWMessage.show("Campaign Saved as Draft Successfully", "success");

                })
                .catch(error => { HWMessage.show("Error while saving campaign", "error") })
                .finally(() => { setLoading(false); history.push('/ops/campaigns'); });
        } else {
            campaignApiService.createCampaign(campaign)
                .then(response => {
                    if (!response || !response.data) { return HWMessage.show("Error while creating campaign as Draft ", "error"); }
                    setCampaignDetail(prev => { prev.status = 'Draft'; return { ...prev }; });
                    HWMessage.show("Campaign Create as Draft Successfully", "success");
                })
                .catch(error => { HWMessage.show("Error while creating campaign as Draft ", "error") })
                .finally(() => {
                    setLoading(false); history.push('/ops/campaigns');
                });
        }
    };

    const saveCampaign = () => {

        var res = validateCampaignValues();
        //  debugger;
        if (res === false) { return HWMessage.show("Campaign has invalid values", "error"); }

        //alert (createMode);

        console.log(locationParams.createMode, locationParams.createMode === false);
        if (locationParams.createMode === true) { console.log("kjahsdfhaskhdhasjidbjkabjkhjdasd"); return createCampaign(campaignDetail); }
        else { console.log((locationParams.createMode === false || (!locationParams.createMode || locationParams.createMode === true) && campaignDetail.id !== null), locationParams.createMode, campaignDetail); return updateCampaign(campaignDetail); }
    };
    console.log("Currrrent", currentStep);

    return (

        <Fragment>
            <GridContainer>
                <GridItem xs={3} md={3} >
                    <Stepper activeStep={currentStep} orientation='vertical' >
                        {
                            steps.map((step, idx) => (
                                <Step key={step} >
                                    <StepLabel > {step} </StepLabel>
                                    <StepContent  >
                                        {/* <Typography variant = "body2" > { step } </Typography> */}
                                    </StepContent>
                                </Step>
                            ))
                        }
                    </Stepper>
                </GridItem>
                <GridItem xs={9} md={9} >
                    {
                        {
                            0: <CampaignSelection advanceStep={advanceStep} backStep={backStep} prevStep={prevStep} currentStep={currentStep} campaignMode={locationParams.createMode ? locationParams.createMode : false} setOpen={setDraftConfirmation} currentCampaign={campaignDetail} campaignType={locationParams.campaignType ? locationParams.campaignType : false} saveDraft={saveAsDraft} currentCampaign={campaignDetail} setCurrentCampaign={setCampaignDetail} steps={['Campaign and User Selection', 'Messaging', 'Points Rule', 'Notify Via', 'Scheduling']} setSteps={setSteps} loading={loading} setLoading={setLoading} campaignCodes={locationParams && locationParams.campaignCodes} />,
                            1: <Messaging advanceStep={advanceStep} backStep={backStep} prevStep={prevStep} currentStep={currentStep} campaignMode={locationParams.createMode ? locationParams.createMode : false} setOpen={setDraftConfirmation} currentCampaign={campaignDetail} campaignType={locationParams.campaignType ? locationParams.campaignType : false} saveDraft={saveAsDraft} setCurrentCampaign={setCampaignDetail} saveDraft={saveAsDraft} />,
                            2: <PointsRule advanceStep={advanceStep} backStep={backStep} prevStep={prevStep} currentStep={currentStep} campaignMode={locationParams.createMode ? locationParams.createMode : false} setOpen={setDraftConfirmation} currentCampaign={campaignDetail} campaignType={locationParams.campaignType ? locationParams.campaignType : false} saveDraft={saveAsDraft} setCurrentCampaign={setCampaignDetail} saveDraft={saveAsDraft} />,
                            3: <Notification advanceStep={advanceStep} backStep={backStep} prevStep={prevStep} currentStep={currentStep} campaignMode={locationParams.createMode ? locationParams.createMode : false} setOpen={setDraftConfirmation} currentCampaign={campaignDetail} campaignType={locationParams.campaignType ? locationParams.campaignType : false} saveDraft={saveAsDraft} setCurrentCampaign={setCampaignDetail} saveDraft={saveAsDraft} />,
                            4: <Scheduling advanceStep={advanceStep} backStep={backStep} prevStep={prevStep} currentStep={currentStep} campaignMode={locationParams.createMode ? locationParams.createMode : false} setSaveConfirmation={setSaveConfirmation} setOpen={setDraftConfirmation} currentCampaign={campaignDetail} campaignType={locationParams.campaignType ? locationParams.campaignType : false} saveDraft={saveAsDraft} setCurrentCampaign={setCampaignDetail} saveCampaign={saveCampaign} saveDraft={saveAsDraft} />
                        }[currentStep]
                    }
                    <ConfirmationDialog open={draftConfirmation} setOpen={setDraftConfirmation} confirmingAction={saveAsDraft} dialogMessage={'Are you sure you want to save as Draft'} dialogTitle={'Save As Draft'} />
                    <ConfirmationDialog open={saveConfirmation} setOpen={setSaveConfirmation} confirmingAction={saveCampaign} dialogMessage={'Are you sure you want to create the campaign'} dialogTitle={'Create the campaign'} />

                </GridItem>
            </GridContainer>
        </Fragment>
    );
};